// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary;
$collapser-dark: $light;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        color: $light;
        font-weight: 600;
        padding: $collapser-gaps-tb 40px $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;
        margin-bottom: 1rem;
        font-size: 15.5px;
        word-break: break-word;
        hyphens: none;

        body.cmsBackend & {
            color: $dark;
        }

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:18px;
            height: 3px;
            content:"";
            position: absolute;
            right: 0.7rem;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {

}
