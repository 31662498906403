/**
 * Grid system
 */

.row {
  margin-left: auto;
  margin-right: auto;
  max-width: rem($rowMaxWidth);
  width: 100%;
  position: relative;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  &.full {
    max-width: none;
  }

  .row {
    margin-left: -#{rem($baseGap)};
    padding-left: 0;
    padding-right: 0;
    max-width: calc(100% + #{rem($baseGap * 2)});
    width: calc(100% + #{rem($baseGap * 2)});
  }

  > .row {
    margin-left:0;
    margin-right:0;
    padding-left:0;
    padding-right:0;
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }

  &.breakRight,
  &.breakLeft {
    max-width: calc(50vw + #{$rowMaxWidth / 2 - 9});
  }
  &.breakRight {
    margin-left: auto;
    margin-right: 0;
    justify-content: flex-start;
  }
  &.breakLeft {
    margin-right: auto;
    margin-left: 0;
    justify-content: flex-end;
  }
}

// Initialize grid
.col {
  float: left;
  padding-left: rem($baseGap);
  padding-right: rem($baseGap);
  max-width: 100%;
  width: 100%;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 100%;
  box-sizing: border-box;
  word-break: break-word;
}

// remove padding left and right from cols
.noGutter,
.noGutter > .col {
  padding-left: 0;
  padding-right: 0;
}

@each $bp, $obj in $gridMap {
  @include breakpoint($bp) {
    @each $key, $val in $obj {
      @if $key == cols or $key == prefix or $key == suffix or $key == width {
        @each $elm in $val {
          @if $key == cols {
            .col.#{$bp}-#{$elm} {
              @if($elm != auto) {
                max-width: grid-size($elm, $columnCount);
                flex-basis: grid-size($elm, $columnCount);
                @include breakpoint(cut) {
                  .row.breakLeft > &, .row.breakRight > & {max-width: rem($rowMaxWidth / 12 * $elm);}
                }
              } @else {
                max-width: 100%;
                flex-basis: 0;
                .row.breakLeft > &, .row.breakRight > & {max-width: 100%;}
              }
            }
          } @else if $key == suffix {
            .col.#{$bp}-suffix-#{$elm} {
              margin-right: grid-size($elm, $columnCount);
            }
          } @else if $key == prefix {
            .col.#{$bp}-prefix-#{$elm} {
              margin-left: grid-size($elm, $columnCount);
            }
          }
        }
      } @else {
        .#{$bp}-#{$key} {
          @each $elm in $val {
            #{nth($elm, 1)}: #{nth($elm, 2)};
          }
        }
      }
    }
  }
}
