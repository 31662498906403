// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $secondary; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: .75em .5rem;
	text-decoration: none;
	text-transform: uppercase;
	border-radius: 10px;
	font-size: rem(16px);
	font-weight: 500;
	color: $navColor;
	@include breakpoint(large) {
		padding: .75em .5rem;
		font-size: 10px;
	}
	@include breakpoint(giant) {
		padding: .75em .75rem;
		font-size: 12px;
	}
	@include breakpoint(huge) {
		padding: 1em 0.75rem;
		font-size: rem(14px);
	}
	@include breakpoint(cut) {
		padding: 1em rem($baseGap);
		font-size: rem(16px);
	}
}
%buttonHover {
	// Link Hover
	color: $secondary;
	background-color: $primary;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, caret-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, caret-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	.branding {
		display: none;
	}
	nav {
		padding: 2rem 0 4rem;
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							padding-top: 2rem;
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
	.add {
		display: none;
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;
	
	#navigation {
		.branding {
			display: block;
			img {
				max-width: rem(180px);
				margin: 1rem;
			}
		}
		.bg {
			display: flex;
			align-items: center;
			background-color: $navBg;
			border-radius: 10px;
			.add {
				display: flex;
				justify-content: space-between;
				width: 60%;
				font-size: 15px;
				float: right;
				padding: 1em rem($baseGap);
				@include breakpoint(giant) {
					font-size: 18px;
					width: 55%;
				}
				@include breakpoint(huge) {
					width: 50%;
				}
				@include breakpoint(full) {
					width: 45%;
				}
			}
			nav {
				padding: 0;
				ul {
					&.navi {
						li {
							&.hasSub {
								.sub {
									padding-top: 0;
									border-radius: 10px;
								}
							}
						}
					}
				}
			}
		}
		body.cmsBackend & {
			display: none;
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	.social {
		display: flex;
		float: left;
		align-items: center;
		flex-direction: row;
		a {
			margin-right: 1rem;
			img {
				width: 25px;
			}
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	flex-direction: column;
	margin-top: 2.5rem;
	padding-top: 2.5rem;
	justify-content: space-between;
	border-top: 1px solid $secondary;
	@include breakpoint($break) {
		flex-direction: row;
		flex-wrap: wrap;
	}
	li {
		margin-right: 1rem;
		a {
			color: $secondary;
			font-weight: 500;
			text-decoration: none;
			&:hover, &.active {
				text-decoration: underline;
			}
		}
	}
}