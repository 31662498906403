
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-bottom: 3rem;
	@include breakpoint(large) {
		margin-bottom: 5rem;
	}

	.swiper-container {
		height: rem(500px);
		@include breakpoint(large) {
			height: rem(600px);
		}
		@include breakpoint(giant) {
			height: rem(900px);
		}
		body:not(.index) & {
			height: rem(200px);
			@include breakpoint(tiny) {
				height: rem(250px);
			}
			@include breakpoint(large) {
				height: rem(450px);
			}
		}
		.swiper-wrapper {
			height: rem(900px);
			.slide1 {
				@include responsiveImage('layout/slider/bgHeader.jpg', (tiny, medium), false);
				background-size: auto;
				background-repeat: no-repeat;
				background-position: top;
			    height: 100%;
				width: auto;
				@include breakpoint(medium) {
					background-position: center;
					background-size: cover;
				}
				@include breakpoint(large) {
					background-position: top;
				}
			}
			.slide2 {
				@include responsiveImage('layout/slider/bgHeader_2.jpg', (tiny, medium), false);
				background-size: auto;
				background-repeat: no-repeat;
				background-position: top;
			    height: 100%;
				width: auto;
				@include breakpoint(medium) {
					background-position: center;
					background-size: cover;
				}
				@include breakpoint(large) {
					background-position: top;
				}
			}
		}

		.content {
			display: block;
			position: absolute;
			top: 8%;
			width: 100%;
			@include breakpoint(large) {
				top: 20%;
			}
			@include breakpoint(giant) {
				top: 40%;
			}
			body.cmsBackend &, body:not(.index) & {
				@include breakpoint(large) {
					top: 27%;
				}
			}
			.branding {
				display: block;
				margin-top: 1rem;
				img {
					display: block;
					width: rem(200px);
					margin: 0 auto;
				}
				@include breakpoint(large) {
					display: none;
				}
				body.cmsBackend &, body:not(.index) & {
					display: none;
				}
			}
			.social {
				display: none;
				@include breakpoint(large) {
					display: flex;
					width: 35px;
					float: left;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;
					a {
						margin-top: 1.5rem;
						img {
							width: 35px;
							&:hover {
								opacity: .5;
							}
						}
					}

					&:before {
						content: "";
						background-color: $primary;
						height: 9rem;
						width: 2px;
						@include breakpoint(huge) {
							height: 16rem;
						}
					}
				}
				body.cmsBackend &, body:not(.index) & {
					&:before {
						height: 4rem;
						@include breakpoint(full) {
							height: 3rem;
						}
					}
				}
			}
			.claim {
				font-size: 18px;
				margin-left: 2rem;
				margin-right: 2rem;
				margin-top: 1rem;
				line-height: 25px;
				font-weight: 600;
				color: $light;
				@include breakpoint(medium) {
					font-size: 25px;
					line-height: 35px;
				}
				@include breakpoint(large) {
					font-size: 28px;
					margin-top: 3rem;
					line-height: 40px;
					margin-left: 4rem;
				}
				body.cmsBackend &, body:not(.index) & {
					display: none;
				}
			}
			.border {
				margin-left: 2rem;
				margin-right: 2rem;
				width: 13rem;
				background-color: $primary;
				border-radius: 10px;
				text-transform: uppercase;
				margin-top: .8rem;
				padding: .5rem;
				font-size: 14px;
				font-weight: 800;
				text-align: center;
				mix-blend-mode: screen;
				@include breakpoint(tiny) {
					width: 20rem;
				}
				@include breakpoint(medium) {
					width: 28rem;
					padding: 2rem 2.5rem;
					font-size: 19px;
				}
				@include breakpoint(large) {
					margin-left: 4rem;
					width: 33rem;
					font-size: 23px;
				}
				body.cmsBackend &, body:not(.index) & {
					display: none;
				}
			}
		}


		.swiper-button-next, .swiper-button-prev {
			top: 25%;
			&:after {
				font-size: 30px;
				color: $light;
			}
			@include breakpoint(large) {
				border: 2px solid $light;
				border-radius: 41px;
				padding: .5rem 1rem;
				top: 85%;
			}
			body:not(.index) & {
				top: 14%;
				@include breakpoint(medium) {
					top: 18%;
				}
				@include breakpoint(large) {
					top: 40%;
				}
			}
		}
		.swiper-button-next {
			@include breakpoint(large) {
				right: 2%;
				left: auto;
			}
			@include breakpoint(full) {
				right: 10%;
				left: auto;
			}
		}
		.swiper-button-prev {
			@include breakpoint(large) {
				left: auto;
				right: 10%;
			}
			@include breakpoint(full) {
				left: auto;
				right: 15%;
			}
		}
		body.cmsBackend & {
			display: none;
		}
	}

}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	// TEASER

	.teaser {

		&.teaser-skew-gallery {
			$transition-speed: .6s;
			$transition-delay: .3s;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			overflow: hidden;

			.skew-side {
				width: 50%;
				height: 30rem;
				transition: all $transition-speed ease-in-out;
				.gallery-item {
					position: relative;
					width: 100%;
					height: 100%;
					border-radius: 0;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					transition: all $transition-speed ease-in-out;

					.gallery-item-content {
						opacity: 0;
						position: absolute;
						top: 0;
						bottom: 0;
						width: 82%;

						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						color: whitesmoke;
						background-color: rgba(65, 76, 92, 0.7);


						transition: all $transition-speed ease-in-out;
						transition-delay: $transition-delay;

						@include breakpoint(large) {
							width: 100%;
						}

						p {
							width: 78%;
							@include breakpoint(medium) {
								width: 50%;
							}

						}
					}

					&:hover {
						.gallery-item-content { opacity: 1; }
					}

				}

				ul.skew-gallery {
					overflow: hidden ;
					position: relative;
					height: 100%;
					list-style: none;
					transition: all $transition-speed ease-in-out;

					li {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						padding-left: 0;
						&:before {
							content: none;
						}
						transition: all $transition-speed ease-in-out;
					}
				}

			}

			&.skewSideLeftHover {
				.skew-side {
					width: 75%;
					&.skew-side-left { margin-right: -15%; }
					&.skew-side-right { margin-right: -35%; }

				}

			}

			&.skewSideRightHover {
				.skew-side {
					width: 75%;
					&.skew-side-left { margin-left: -35%; }
					&.skew-side-right { margin-left: -15%; }

				}
			}

		}

		@supports ( clip-path: polygon(0 0)) or ( -webkit-clip-path: polygon(0 0) ) {
			&.teaser-skew-gallery {
				.skew-side {
					width: 55%;
					&.skew-side-left { margin-right: -10%; }
					// .skew-gallery {
					//     a { width: 100% !important; }
					// }
				}

				&.teaser-skew-oriented-right {
					margin: 1rem 0 2rem;
					@include breakpoint(large) {
						margin: 4.5rem 0 3rem;
					}
					.skew-side {
						&.skew-side-left { clip-path: polygon(0 0%, 100% 0%, 75% 100%, 0% 100%); }
						&.skew-side-right { clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%); }
						ul.skew-gallery {
							li {
								clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
							}
						}
					}
				}

				&.teaser-skew-oriented-left {
					margin-bottom: 2rem;
					@include breakpoint(large) {
						margin-bottom: 5rem;
					}
					.skew-side {
						&.skew-side-left { clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%); }
						&.skew-side-right { clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%); }
						ul.skew-gallery {
							li { clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%); }
						}
					}
				}


				@mixin skew-gallery-el-hover( $inverse: false ) {
					@for $iteration from 1 through 4 {
						ul.skew-gallery {
							&.el-#{$iteration}-hover {
								$maxIndices: 5;
								$space: 6;

								// NOTE: Starts at 2 because the first item should always serve as the base background
								@for $i from 2 through $iteration+1 {
									$left: percentage( ( $space * ($i - 1) ) / 100 );
									@if $inverse {
										$left: $left * -1;
									}
									li:nth-child(#{$i}) { left: $left !important; }
								}

								@for $i from $iteration+1 through 5 {
									$maxIndices: $maxIndices - $i;
									$left: ( 100 - ( $space * $maxIndices ) );
									$left: percentage( $left / 100 );
									@if $inverse {
										$left: $left * -1;
									}
									li:nth-child(#{$i}) { left: $left !important; }
									$maxIndices: 5;
								}
							}
						}
					}
				}


				@include skew-gallery-el-hover(false);



				&.skew-gallery-left {
					&.teaser-skew-oriented-right {
						.skew-side-left {
							clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
							ul.skew-gallery {
								li {
									clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
								}
							}
						}
					}
					&.teaser-skew-oriented-left {
						.skew-side-left {
							clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
							ul.skew-gallery {
								li {
									clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
								}
							}
						}
					}
					@include skew-gallery-el-hover(true);
				}
			}
		}
		@media (max-width: 991.98px) {
			&.teaser-skew-gallery {
				flex-direction: column;
				.skew-side {
					width: 100% !important;
				}
				div, li {
					clip-path: none !important;
				}

				&.skewSideLeftHover {
					.skew-side {
						width: 100%;
						&.skew-side-left { margin-right: 0; }
						&.skew-side-right { margin-right: 0; }

					}

				}

				&.skewSideRightHover {
					.skew-side {
						width: 100%;
						&.skew-side-left { margin-left: 0; }
						&.skew-side-right { margin-left: 0; }

					}
				}
			}
		}
	}


	.teaser {
		// .contentGabio, .contentZaun, .contentReparatur, .contentGelaender, .contentSichtschutz, .contentCarport {

		// }
		.contentGabio {
			.maschendraht {
				@include responsiveImage('index/teaser/maschendrahtzaun.jpg', (tiny), true);
			}
		}
		.contentZaun {
			.doppelstabgitter {
				@include responsiveImage('index/teaser/doppelstabgitterzaun.jpg', (tiny), true);
			}
			.glaszaun {
				@include responsiveImage('index/teaser/glaszaun.jpg', (tiny), true);
			}
			.alu-zaun {
				@include responsiveImage('index/teaser/alu-zaun.jpg', (tiny), true);
			}
		}
		.contentReparatur {
			.reparaturarbeiten {
				@include responsiveImage('index/teaser/reparaturarbeiten.jpg', (tiny), true);
			}
		}
		.contentGelaender {
			.stiegengelaender {
				@include responsiveImage('index/teaser/stiegengelaender.jpg', (tiny), true);
			}
			.balkongelaender {
				@include responsiveImage('index/teaser/balkongelaender.jpg', (tiny), true);
			}
			.grille {
				@include responsiveImage('index/teaser/grill.jpg', (tiny), true);
			}
		}
		.contentSichtschutz {
			.betonzaun {
				@include responsiveImage('index/teaser/betonzaun.jpg', (tiny), true);
			}
			.sichtschutz {
				@include responsiveImage('index/teaser/sichtschutz.jpg', (tiny), true);
			}
		}
		.contentCarport {
			.carports {
				@include responsiveImage('index/teaser/carport.jpg', (tiny), true);
			}
		}

	}
	// TEASER END

	.gabio {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 3rem 0;
		padding: 2rem 3rem;
		border: 1px solid $secondary;
		border-radius: 10px;
		@include breakpoint(tiny) {
			flex-direction: row;
		}
		@include breakpoint(medium) {
			flex-direction: column;
		}
		@include breakpoint(huge) {
			flex-direction: row;
		}
		p {
			font-size: 20px;
			line-height: 30px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 0;
			margin-right: 2rem;
			@include breakpoint(tiny) {
				font-size: 16px;
				line-height: 23px;
			}
			@include breakpoint(medium) {
				font-size: 18px;
				line-height: 27px;
			}
			@include breakpoint(large) {
				font-size: 20px;
				line-height: 30px;
			}
		}
		img {
			width: rem(400px);
			margin-top: .5rem;
			margin-bottom: 0;
			@include breakpoint(small) {
				width: rem(250px);
				margin-top: 0;
			}
			@include breakpoint(large) {
				width: rem(450px);
			}
		}
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

}

#privacyPolicy
{
	a
	{
		display: inline-block;
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	color: $secondary;
	margin-top: 5rem;
	padding: 1rem 0;
	.googleMaps {
		height: 100%;
		iframe {
			border-radius: 10px;
			@include breakpoint(large) {
				border-radius: 10px 0 0 10px;
			}
		}
	}
	.grey {
		padding-top: 2rem;
		background: $primary;
		@include breakpoint(large) {
			padding-top: 0;
			background: transparent;
		}
	}
	.bg {
		display: flex;
		font-weight: 500;
		flex-direction: column;
		justify-content: center;
		background: $primary;
		padding: 3rem 1.6rem;
		position: relative; 
		.client {
			font-size: 20px;
			font-weight: 800;
			margin-bottom: .8rem;
		}
		@include breakpoint(medium) {
			padding: 4rem;
		}
		@include breakpoint(large) {
			border-radius: 0 10px 10px 0;
			padding: 4rem 3rem;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: -40px;
				height: 100%;
				width: 40px;
				background: #e2e3dd;
			}
		}
	}
}

p.name {
	font-size: rem(26px);
	font-weight: 600;
}

body.gartendekoration.cmsBackend {

	.knopf {
		display: none;
	}
}